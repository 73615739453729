<template>
  <div>
    <v-row class="mx-0 my-0">
      <v-col
        v-for="(item, index) in menuItemArr"
        :key="index"
        cols="6"
        sm="6"
        md="4"
        class="px-1 mb-1 cursor-pointer"
        style="padding-top:2px; padding-bottom:2px;"
        @click="selectBao(index, item.itemId)"
      >
        <div class="item-wrap d-flex flex-column align-center flex-sm-row pa-2">
          <img class="item-img text-center" :src="getItemImg(item.images[0].url)" alt="item" />
          <div class="item-right flex-grow-1 d-flex flex-column justify-sm-center align-center align-sm-start pl-1 ml-0 ml-sm-1">
            <div class="item-label mb-1" :class="{ 'text-center': isMobileView }">{{ item.name }}</div>
            <div class="item-price font-weight-bold mb-1">{{ `${$t('lbl.rm')}${priceFormat(item.price)}` }}</div>
          </div>
        </div>
      </v-col>
      <!-- <v-divider class="set-divider mx-2 my-2"></v-divider> -->
    </v-row>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      defaultImg: require('@/assets/logo.png'),
      menuItemArr: [],
      boxMenuId: 6,
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
    }),
    menuId() {
      return this.$route.params.menuId;
    },
    step() {
      return this.$route.query.step;
    },
  },
  created() {
    const baoItem = this.menuItemList.find(item => item.menuId == this.menuId);
    const baoOptGrp = baoItem.optGrp[0];
    let optGrpSub = this.menuItemOptGrpSubList.find(item => item.itemId == baoItem.itemId && item.grpId == baoOptGrp);
    const optItemIds = optGrpSub.optItemIds;

    this.menuItemArr = optItemIds.map(item => {
      const menuItem = this.menuItemList.find(menuItem => menuItem.itemId == item);
      const optItem = this.menuItemOptList.find(optItem => optItem.itemId == item && optItem.grpId == baoOptGrp);
      return {
        ...menuItem,
        price: menuItem.price + baoItem.price,
        images: optItem.images,
      };
    });
  },
  methods: {
    getItemImg(imgUrl) {
      if (this.isUndefinedNullOrEmpty(imgUrl)) {
        return this.defaultImg;
      }
      return imgUrl;
    },
    selectBao(index, itemId) {
      this.$emit('next', itemId);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../styles/mixin.scss';

.item-wrap {
  background-color: #fff5d3;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 1px #8e8e8e;
  height: 100%;
  min-width: 33%;

  &.selected {
    border: 4px solid #00c569;
    box-shadow: none;
  }
}
.item-price {
  color: var(--red-common);
  font-size: 0.95rem;
  font-weight: 500;
}
.item-img {
  max-width: 150px;
  padding: 10px;
  width: 100%;
  @include for-sm {
    max-width: 140px;
    padding: 0px;
  }
}
</style>
