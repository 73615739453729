<template>
  <div>
    <SetItemScreen2 v-if="step == 1" @next="next" />
    <SetItem v-if="step == 2" :bao-item-id="baoItemId" @back="back" />
  </div>
</template>
<script>
import SetItemScreen2 from './SetItemScreen2';
import SetItem from './SetItem';
import { mapState, mapMutations } from 'vuex';
export default {
  components: {
    SetItemScreen2,
    SetItem,
  },
  data() {
    return {
      baoItemId: null,
      boxMenuId: 6,
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
    }),
    menuId() {
      return this.$route.params.menuId;
    },
    step() {
      return this.$route.query.step;
    },
  },
  created() {
    const menu = this.menuList.find(item => item.id == this.menuId);
    this.SET_DRAWER_TITLE(menu.name);
    // if page refresh, back to step 1
    this.navigatePush('MenuSetItem', { menuId: this.menuId }, { step: 1 });
  },
  methods: {
    ...mapMutations(['SET_DRAWER_TITLE']),
    back() {
      this.navigateReplace('MenuSetItem', { menuId: this.menuId }, { step: 1 });
    },
    next(baoItemId) {
      this.baoItemId = baoItemId;
      this.navigatePush('MenuSetItem', { menuId: this.menuId }, { step: 2 });
    },
  },
};
</script>
