<template>
  <div style="margin-bottom: 80px;">
    <div class="top-item-wrap pa-1 mb-2">
      <!-- <div class="px-2 mb-1 font-1dot2r font-weight-medium">{{ baoObj.name }}</div> -->
      <v-row class="mx-0 my-0">
        <v-col cols="12" sm="6" md="6" class="pa-0">
          <div class="d-flex px-2 py-0">
            <div class="item-left text-center" style="width: 25%">
              <img class="top-item-img" :src="getItemImg(baoObj.images[0].url)" alt="item" />
            </div>
            <div class="item-right d-flex flex-column justify-center align-start flex-grow-1 px-1">
              <div class="top-item-label mb-1">{{ `${baoObj.name}` }}</div>
              <div class="top-item-price font-weight-bold">{{ `${$t('lbl.rm')}${priceFormat(baoObj.price)}` }}</div>
            </div>
            <!-- <div class="d-flex align-center">
                <v-radio :key="index" :value="item.itemId" color="#17bb1e"></v-radio>
              </div> -->
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="item-wrap pa-2 mb-2">
      <div class="px-2 mt-2 mb-2 font-1r font-weight-medium">Choose Sauce</div>
      <v-radio-group v-model="selectedBao">
        <v-row class="mx-0 my-0">
          <template v-for="(item, index) in sauceItemArr">
            <v-col :key="index" cols="12" sm="6" md="6" class="pa-0">
              <div class="d-flex px-2 py-0 cursor-pointer" @click="selectedBao = item.itemId">
                <div class="item-left text-center" style="width: 18%">
                  <img class="item-img" :src="getItemImg(item.images[0].url)" alt="item" />
                </div>
                <div class="item-right d-flex flex-column justify-center align-start flex-grow-1 px-1">
                  <div class="item-label mb-1">{{ `${item.name}` }}</div>
                  <!-- <div class="item-price font-weight-bold">{{ `${$t('lbl.rm')}${priceFormat(item.price)}` }}</div> -->
                </div>
                <div class="d-flex align-center">
                  <!-- <PlusMinusField class="plus-minus-field" filled outlined dense v-model="baoForm[item.itemId].count" /> -->
                  <!-- <Checkbox v-model="baoForm[item.itemId].selected" @click.native.stop="" /> -->
                  <v-radio :key="index" :value="item.itemId" color="#17bb1e"></v-radio>
                </div>
              </div>
              <hr v-if="sauceItemArr.length - 1 != index && isMobileView" class="dotted-separator" />
            </v-col>
          </template>
        </v-row>
      </v-radio-group>
      <!-- <v-divider class="set-divider mx-2 my-2"></v-divider> -->
    </div>

    <!-- Beverage start -->
    <div class="item-wrap pa-2 mb-2">
      <div class="px-2 mt-2 mb-2 font-1r font-weight-medium">Beverage</div>
      <v-row class="mx-0 my-0">
        <template v-for="(item, index) in beverageArr">
          <v-col :key="index" cols="12" sm="6" md="6" class="pa-0">
            <div class="d-flex px-2 py-0 cursor-pointer" @click="selectBeverage2(item.itemId)">
              <div class="item-left text-center" style="width: 18%">
                <img class="item-img" :src="getItemImg(item.images[0].url)" alt="item" />
              </div>
              <div class="item-right d-flex flex-column justify-center align-start flex-grow-1 px-1">
                <div class="item-label mb-1">{{ item.name }}</div>
                <!-- <div class="item-price font-weight-bold">{{ `+${$t('lbl.rm')}${priceFormat(item.price)}` }}</div> -->
              </div>
              <div class="d-flex align-center">
                <!-- <PlusMinusField
                  v-model="beverageForm2[item.itemId].count"
                  class="plus-minus-field"
                  filled
                  outlined
                  dense
                  :max-value="beverageLimit"
                  :disabled-plus="beverageCount >= beverageLimit"
                /> -->
                <!-- <v-radio :key="index" :value="item.itemId" color="#17bb1e"></v-radio> -->

                <Checkbox v-model="beverageForm2[item.itemId].selected" @click.native.stop="selectBeverage(item.itemId)" />
                <!-- :disabled="beverageSelected && !beverageForm2[item.itemId].selected" -->

              </div>
            </div>
            <hr v-if="beverageArr.length - 1 != index && isMobileView" class="dotted-separator" />
          </v-col>
        </template>
      </v-row>
      <!-- <v-divider class="set-divider mx-2 my-2"></v-divider> -->
    </div>
    <!-- Beverage end -->

    <!-- Add on items start -->
    <v-expansion-panels v-if="menuId == boxMenuId" v-model="addOnExpand" class="order-summ mb-2" accordion>
      <v-expansion-panel>
        <v-expansion-panel-header class="px-3 py-2">
          <div class="d-flex justify-space-between align-center">
            <div class="px-2 mt-2 mb-2 font-1r font-weight-medium">Add On</div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mx-0 my-0">
            <template v-for="(item, index) in addOnArr">
              <v-col :key="index" cols="12" sm="6" md="6" class="pa-0">
                <div
                  class="d-flex px-2 py-0 cursor-pointer"
                  @click="addOnForm[item.itemId].selected = !addOnForm[item.itemId].selected"
                >
                  <div class="item-left text-center" style="width: 18%">
                    <img class="item-img" :src="getItemImg(item.images[0].url)" alt="item" />
                  </div>
                  <div class="item-right d-flex flex-column justify-center align-start flex-grow-1 px-1">
                    <div class="item-label mb-1">{{ item.name }}</div>
                    <div class="item-price font-weight-bold">{{ `+${$t('lbl.rm')}${priceFormat(item.price)}` }}</div>
                  </div>
                  <div class="d-flex align-center">
                    <Checkbox v-model="addOnForm[item.itemId].selected" @click.native.stop="" />
                  </div>
                </div>
                <hr v-if="addOnArr.length - 1 != index && isMobileView" class="dotted-separator" />
              </v-col>
            </template>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- <div v-if="menuId == boxMenuId" class="item-wrap pa-2 mb-2">
      <div class="px-2 mb-1 font-1dot2r font-weight-medium">Add On</div>
      <v-row class="mx-0 my-0">
        <template v-for="(item, index) in addOnArr">
          <v-col :key="index" cols="12" sm="6" md="6" class="pa-0">
            <div
              class="d-flex px-2 py-0 cursor-pointer"
              @click="addOnForm[item.itemId].selected = !addOnForm[item.itemId].selected"
            >
              <div class="item-left text-center" style="width: 18%">
                <img class="item-img" :src="getItemImg(item.images[0].url)" alt="item" />
              </div>
              <div class="item-right d-flex flex-column justify-center align-start flex-grow-1 px-1">
                <div class="item-label mb-1">{{ item.name }}</div>
                <div class="item-price font-weight-bold">{{ `+${$t('lbl.rm')}${priceFormat(item.price)}` }}</div>
              </div>
              <div class="d-flex align-center">
                <Checkbox v-model="addOnForm[item.itemId].selected" @click.native.stop="" />
              </div>
            </div>
            <hr v-if="addOnArr.length - 1 != index && isMobileView" class="dotted-separator" />
          </v-col>
        </template>
      </v-row>
    </div> -->
    <!-- Add on items end -->

    <!-- Extra sauce start -->
    <v-expansion-panels v-model="extraSauceExpand" class="order-summ" accordion>
      <v-expansion-panel>
        <v-expansion-panel-header class="px-3 py-2">
          <div class="d-flex justify-space-between align-center">
            <div class="px-2 mt-2 mb-2 font-1r font-weight-medium">Extras</div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mx-0 my-0">
            <template v-for="(item, index) in sauceArr">
              <v-col :key="index" cols="12" sm="6" md="6" class="pa-0">
                <div
                  class="d-flex px-2 py-0 cursor-pointer"
                  @click="sauceForm[item.itemId].selected = !sauceForm[item.itemId].selected"
                >
                  <div class="item-left text-center" style="width: 18%">
                    <img class="item-img" :src="getItemImg(item.images[0].url)" alt="item" />
                  </div>
                  <div class="item-right d-flex flex-column justify-center align-start flex-grow-1 px-1">
                    <div class="item-label mb-1">{{ item.name }}</div>
                    <div class="item-price font-weight-bold">{{ `+${$t('lbl.rm')}${priceFormat(item.price)}` }}</div>
                  </div>
                  <div class="d-flex align-center">
                    <Checkbox v-model="sauceForm[item.itemId].selected" @click.native.stop="" />
                  </div>
                </div>
                <hr v-if="sauceArr.length - 1 != index && isMobileView" class="dotted-separator" />
              </v-col>
            </template>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- <div class="item-wrap pa-2 mb-2">
      <div class="px-2 mb-1 font-1dot2r font-weight-medium">Extras</div>
      <v-row class="mx-0 my-0">
        <template v-for="(item, index) in sauceArr">
          <v-col :key="index" cols="12" sm="6" md="6" class="pa-0">
            <div
              class="d-flex px-2 py-0 cursor-pointer"
              @click="sauceForm[item.itemId].selected = !sauceForm[item.itemId].selected"
            >
              <div class="item-left text-center" style="width: 18%">
                <img class="item-img" :src="getItemImg(item.images[0].url)" alt="item" />
              </div>
              <div class="item-right d-flex flex-column justify-center align-start flex-grow-1 px-1">
                <div class="item-label mb-1">{{ item.name }}</div>
                <div class="item-price font-weight-bold">{{ `+${$t('lbl.rm')}${priceFormat(item.price)}` }}</div>
              </div>
              <div class="d-flex align-center">
                <Checkbox v-model="sauceForm[item.itemId].selected" @click.native.stop="" />
              </div>
            </div>
            <hr v-if="sauceArr.length - 1 != index && isMobileView" class="dotted-separator" />
          </v-col>
        </template>
      </v-row>
    </div> -->
    <!-- Extra sauce end -->

    <div class="bottom-bar d-flex justify-space-between">
      <div class="d-flex flex-column justify-center">
        <div class="total-text">{{ $t('lbl.total') }}</div>
        <div class="total-price">
          <span class="font-14">{{ $t('lbl.rm') }}</span>
          <span>{{ priceFormat(setTotalPrice) }}</span>
        </div>
      </div>
      <div class="d-flex justify-center align-center">
        <PlusMinusField v-model="qty" class="qty-bar px-4" :min-value="1" filled outlined dense />
      </div>
      <div class="d-flex align-center">
        <AppBtn class="btn-orange-border px-3" :height="40" :loading="loading" @click="preAddToCart">{{
          $t('act.addToCart')
        }}</AppBtn>
      </div>
    </div>
    <CommonModal v-model="successModal" :modal-size="'modal-xs'" :header="false" :footer="false">
      <template #body>
        <div class="modal-body d-flex align-center">
          <v-icon class="mr-2" color="var(--primary-color)" size="45">mdi-check-circle-outline</v-icon>
          <div class="modal-body__text">{{ $t('cart.success.add') }}</div>
        </div>
      </template>
    </CommonModal>
    <MessageModal
      v-if="errMsgModalShow"
      v-model="errMsgModalShow"
      :modal-size="'modal-xs-2'"
      :header-title="msgModalTitle"
      :msg="msgModalBody"
      @confirm="errMsgModalShow = false"
    >
      <template #header>
        <div></div>
      </template>
    </MessageModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { MENU_GRP } from '@/common/enum/menu';
import CommonModal from '@/components/Modal';
import MessageModal from '@/components/Modal/MessageModal';
export default {
  name: 'ItemList',
  components: {
    CommonModal,
    MessageModal,
  },
  props: {
    baoItemId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedBao: null,
      beverageSelected: true,

      // cnt: count limit, count: currently selected count
      baoLimit: 1,
      baoGrpId: null,

      initBaoForm: {},

      baoForm: {},
      baoArr: [],
      baoArrCnt: [],
      baoArrDisabled: [],

      beverageLimit: 1,
      beverageGrpId: null,

      beverageForm: {},
      beverageDisabled: false,
      beverageGrpItemsArr: [],

      beverageBasePrice: 0,

      setTotalPrice: 0,
      setBasePrice: 0,
      setPrice: 0,
      totalBeveragePrice: 0,

      promotionItemMenuId: 9,

      loading: false,
      qty: 1,

      menuItem: {},
      modalShow: false,
      successModal: false,

      selectedDrink: null,
      menuDetails: {},
      baoGroupErrArr: [],

      errMsgModalShow: false,
      msgModalTitle: '',
      msgModalBody: 'Please select all required items.',

      defaultImg: require('@/assets/logo.png'),

      defaultDrinkId: 301 /* Default drink */,
      initFixedMenuPrice: 0,

      addOnMenuId: 13, // from /menu/item/list
      addOnArr: [],
      addOnForm: {},
      addOnTotalAmt: 0,

      sauceMenuId: 15, // from /menu/item/list
      sauceArr: [],
      sauceForm: {},
      sauceTotalAmt: 0,

      beverageArr: [],
      beverageForm2: {},
      beverageTotalAmt: 0,

      menuItemDetails: {},

      boxMenuId: 6,

      baoObj: {},
      sauceItemArr: [],

      beverageCount: 0,
      beverageRequired: false,

      addOnExpand: 0,
      extraSauceExpand: 0,
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
    }),
    menuId() {
      return this.$route.params.menuId;
    },
    // menuLabel() {
    //   return this.menuList.find(item => item.id == this.menuId).name;
    // },
    itemListByMenuId() {
      if (this.isUndefinedNullOrEmpty(this.menuItemList) || this.isUndefinedNullOrEmpty(this.menuId)) return;
      return this.menuItemList.filter(item => item.menuId == this.menuId);
    },
    baoCountByMenuId() {
      if (this.isUndefinedNullOrEmpty(this.menuItemList) || this.isUndefinedNullOrEmpty(this.menuId)) return;
      let firstItem = this.menuItemList.find(item => item.menuId == this.menuId);
      return this.menuItemOptGrpSubList.find(item => item.itemId == firstItem.itemId && item.grpId == firstItem.optGrp[0]).cnt;
    },
    baoOptItemIdsByMenuId() {
      if (this.isUndefinedNullOrEmpty(this.menuItemList) || this.isUndefinedNullOrEmpty(this.menuId)) return;
      let firstItem = this.menuItemList.find(item => item.menuId == this.menuId);

      let finalArr = [];
      for (let i = 0; i < firstItem.optGrp.length - 1 /* Minus 1 to filter out drinks */; i++) {
        finalArr[i] = this.menuItemOptGrpSubList.find(
          item => item.itemId == firstItem.itemId && item.grpId == firstItem.optGrp[i]
        ).optItemIds;
      }
      return finalArr;
    },
    baoOptsByMenuId() {
      let finalArr = [];
      for (let i = 0; i < this.baoOptItemIdsByMenuId.length; i++) {
        finalArr[i] = this.baoOptItemIdsByMenuId[i].map(itemId => {
          return this.menuItemOptList.find(item => item.grpId == this.baoGrpId && item.itemId == itemId);
        });
      }
      return finalArr;
    },
    beverageItemsByMenuId() {
      if (this.isUndefinedNullOrEmpty(this.menuItemList) || this.isUndefinedNullOrEmpty(this.menuId)) return;
      let filteredItem = this.menuItemList.filter(item => item.menuId == this.menuId);
      return filteredItem;
    },
    MENU_GRP() {
      return MENU_GRP;
    },
    isFixedPriceSet() {
      return this.menuDetails.grp == MENU_GRP.FIXED_PRICE;
    },
    freeDrinks() {
      return this.beverageGrpItemsArr[0][0];
    },
  },
  watch: {
    // baoForm: {
    //   deep: true,
    //   handler(value) {
    //     let count = 0;
    //     for (let i = 0; i < Object.keys(value).length; i++) {
    //       const item = value[Object.keys(value)[i]];
    //       count += item.count;
    //     }

    //     this.setBasePrice = this.setPrice * count;
    //     this.setTotalPrice = this.setBasePrice + this.sauceTotalAmt + this.addOnTotalAmt;

    //     for (let key in this.beverageForm2) {
    //       this.$set(this.beverageForm2, key, {
    //         ...this.beverageForm2[key],
    //         count: 0,
    //       });
    //     }

    //     const beverageItemId = Object.keys(this.beverageForm2)[0];

    //     this.$set(this.beverageForm2, beverageItemId, {
    //       ...this.beverageForm2[beverageItemId],
    //       count,
    //     });

    //     this.beverageLimit = count;
    //     // this.qty = 1; // reset qty to 1
    //   },
    // },
    beverageForm2: {
      deep: true,
      handler(value) {
        let count = 0;
        for (let key in this.beverageForm2) {
          count += this.beverageForm2[key].count;
        }
        this.beverageCount = count;
        // let count = 0;
        // this.totalBeveragePrice = 0;
        // for (let key in value) {
        //   count = count + value[key].count;
        //   this.totalBeveragePrice = this.totalBeveragePrice + value[key].count * value[key].addPrice;
        //   if (count >= this.beverageLimit) {
        //     this.beverageDisabled = true;
        //     break;
        //   }
        // }
        // if (count == 0) this.beverageDisabled = false;

        // this.setBasePrice = this.setPrice + this.totalBeveragePrice;
        // this.setTotalPrice = this.setPrice + this.totalBeveragePrice;
        // this.qty = 1; // reset qty to 1
      },
    },
    addOnForm: {
      deep: true,
      handler(value) {
        let addOnTotalAmt = 0;
        for (let key in value) {
          if (value[key].selected) {
            addOnTotalAmt += value[key].price;
          }
        }
        this.addOnTotalAmt = addOnTotalAmt;

        this.setBasePrice = this.setPrice + this.sauceTotalAmt + addOnTotalAmt;
        this.setTotalPrice = this.setBasePrice * this.qty;
      },
    },
    sauceForm: {
      deep: true,
      handler(value) {
        let sauceTotalAmt = 0;
        for (let key in value) {
          if (value[key].selected) {
            sauceTotalAmt += value[key].price;
          }
        }
        this.sauceTotalAmt = sauceTotalAmt;

        this.setBasePrice = this.setPrice + this.addOnTotalAmt + sauceTotalAmt;
        this.setTotalPrice = this.setBasePrice * this.qty;
      },
    },
    qty(value) {
      this.setTotalPrice = this.setBasePrice * value;
    },
  },
  created() {
    // this.SET_DRAWER_TITLE('Set');
    if (
      !this.isUndefinedNullOrEmpty(this.menuItemList) &&
      !this.isUndefinedNullOrEmpty(this.menuId) &&
      !this.isUndefinedNullOrEmpty(this.baoItemId)
    ) {
      const menuItemObj = this.menuItemList.find(item => item.menuId == this.menuId);

      // Process bao data
      this.baoObj = JSON.parse(JSON.stringify(this.menuItemList.find(item => item.itemId == this.baoItemId)));
      this.baoObj.price += menuItemObj.price;

      const bunOptGrp = menuItemObj.optGrp[0];

      const baoOptItem = this.menuItemOptList.find(optItem => optItem.itemId == this.baoItemId && optItem.grpId == bunOptGrp);
      this.baoObj.images = baoOptItem.images;

      const sauceOptGrp = menuItemObj.optGrp[1];

      let optGrpSub = this.menuItemOptGrpSubList.find(item => item.itemId == menuItemObj.itemId && item.grpId == sauceOptGrp);
      const optItemIds = optGrpSub.optItemIds;

      // Assumption: Last item in optGrp is beverage
      const beverageOptGrp = menuItemObj.optGrp[menuItemObj.optGrp.length - 1];
      const beverageGrpSub = this.menuItemOptGrpSubList.find(
        item => item.itemId == menuItemObj.itemId && item.grpId == beverageOptGrp
      );

      this.beverageRequired = beverageGrpSub.flag == 0 ? true : false;

      this.sauceItemArr = optItemIds
        .map(item => {
          return this.menuItemList.find(menuItem => menuItem.itemId == item);
        })
        .map(item => {
          return {
            ...item,
            count: 0,
            price: this.baoObj.price + menuItemObj.price,
          };
        });

      this.menuItemDetails = menuItemObj;
      this.menuDetails = this.menuList.find(item => item.id == this.menuId);

      // this.promotionItems = this.menuItemList.filter(item => item.menuId == this.promotionItemMenuId);

      // Process add on items arr & form
      this.addOnArr = this.menuItemList.filter(item => item.menuId == this.addOnMenuId);
      for (let item of this.addOnArr) {
        this.$set(this.addOnForm, item.itemId, {
          ...item,
          selected: false,
        });
      }

      // Process extra sauce arr & form
      this.sauceArr = this.menuItemList.filter(item => item.menuId == this.sauceMenuId);
      for (let item of this.sauceArr) {
        this.$set(this.sauceForm, item.itemId, {
          ...item,
          selected: false,
        });
      }

      // Process beverage arr & form
      this.beverageArr = beverageGrpSub.optItemIds.map(item => {
        const menuItem = this.menuItemList.find(menuItem => menuItem.itemId == item);
        return {
          ...menuItem,
          price: 0,
        };
      });

      for (let [index, item] of Object.entries(this.beverageArr)) {
        this.$set(this.beverageForm2, item.itemId, {
          ...item,
          price: 0, // 10/8/2021: Free drinks for bun & box
          selected: index == 0 ? true : false, // Set first beverage as default selected
          // count: index == 0 ? 1 : 0, // Set first beverage as default selected
        });
      }

      // console.log('beverageForm2', JSON.stringify(this.beverageForm2, '', 2));

      let baoArr = [];
      for (let i = 0; i < this.sauceItemArr.length; i++) {
        const sauce = this.sauceItemArr[i];

        this.$set(this.baoForm, sauce.itemId, {
          ...sauce,
          // count: 0,
        });
      }
      // this.initBaoForm = JSON.parse(JSON.stringify(this.baoForm));
      this.baoArr = baoArr;

      this.setPrice = this.baoObj.price;
      // this.setBasePrice = this.setPrice;
      this.initFixedMenuPrice = this.setPrice;

      // for (let i = 0; i < baoLoopLength; i++) {
      //   baoArr[i] = {};
      //   baoArr[i].price = this.menuDetails.price;
      //   baoArr[i].grp = this.menuDetails.grp;
      //   baoArr[i].images = this.menuDetails.images;
      //   baoArr[i].cnt = optGrpSub.cnt;

      //   this.baoArrCnt[i] = {};
      //   this.baoArrCnt[i].cnt = optGrpSub.cnt;
      //   this.baoArrDisabled[i] = false;
      //   baoArr[i].grpName = this.menuItemOptGrpList.find(item => item.id == firstItem.optGrp[i]).name;
      //   let baoItems = optGrpSub.optItemIds.map(optGrpId => {
      //     return this.menuItemOptList.find(item2 => item2.grpId == firstItem.optGrp[i] && item2.itemId == optGrpId);
      //   });

      //   for (let item of baoItems) {
      //     const menuItemObj = this.menuItemList.find(menuItem => menuItem.itemId == item.itemId);
      //     item.images = !this.isUndefinedNullOrEmpty(menuItemObj.images) ? menuItemObj.images : [{ url: null }];
      //     item.price = this.menuId == this.boxMenuId ? menuItemObj.price + 2 : menuItemObj.price;
      //     // const promoItemObj = this.promotionItems.find(promoItem => promoItem.recId == item.itemId && promoItem.setId == this.menuId);
      //     // item.promoPrice = !this.isUndefinedNullOrEmpty(promoItemObj?.price) ? promoItemObj?.price : null;
      //   }
      //   baoArr[i].items = baoItems;
      // }

      // Process beverage data
      // let filteredItems = this.menuItemList.filter(item => item.menuId == this.menuId);
      // let beverageGrp = [];
      // for (let item of filteredItems) {
      //   let temp = {
      //     ...item,
      //     items: [...this.menuItemOptList.filter(itemOpt => itemOpt.grpId == item.optGrp[item.optGrp.length - 1])],
      //   };
      //   beverageGrp.push(temp);
      // }

      // let beverageGrpItemsArr = beverageGrp.map(beverage => {
      //   let beverageItemId = beverage.itemId;
      //   let beverageOptGrpId = beverage.optGrp[beverage.optGrp.length - 1];
      //   let beverageOptItem = this.menuItemOptGrpSubList.find(
      //     item => item.itemId == beverageItemId && item.grpId == beverageOptGrpId
      //   );
      //   let beverageOptItemId = beverageOptItem.optItemIds[0]; // Get first item

      //   let beverageOptGrp = this.menuItemOptGrpList.find(item => item.id == beverageOptGrpId);

      //   let selectedItem = this.menuItemList.find(item => item.itemId == beverageOptItemId); // To get price & info of this beverage group
      //   return beverage.items.map(item => {
      //     return {
      //       // ...beverage,
      //       grpName: beverageOptGrp.name,
      //       menuId: beverage.menuId,
      //       menuItemId: beverage.itemId,
      //       setBase: selectedItem.setBase,
      //       price: selectedItem.price,
      //       ...item,
      //     };
      //   });
      // });
      // this.beverageBasePrice = beverageGrpItemsArr[0][0].setBase;
      // this.selectedDrink = beverageGrpItemsArr[0][0].itemId;

      // for (let beverageGrp of beverageGrpItemsArr) {
      //   for (let i = 0; i < beverageGrp.length; i++) {
      //     let temp = beverageGrp[i];
      //     let count = temp.setBase > 0 ? 1 : 0;
      //     this.$set(this.beverageForm, temp.itemId, {
      //       ...temp,
      //       addPrice: temp.price == 0 ? 0 : temp.price - this.beverageBasePrice,
      //       count,
      //     });
      //   }
      // }

      // this.beverageGrpItemsArr = beverageGrpItemsArr;
    }
  },
  methods: {
    ...mapMutations(['SET_DRAWER_TITLE']),
    ...mapActions(['addToCart']),
    preAddToCart() {
      // for (let key in this.baoForm) {
      //   let baoItem = this.baoForm[key];
      //   if (baoItem.selected) {
      //     items.push(
      //       { qty: 1, itemId: this.baoObj.itemId, name: this.baoObj.name },
      //       { qty: 1, itemId: baoItem.itemId, name: baoItem.name }
      //     );
      //     break;
      //   }
      // }

      if (this.loading) {
        return;
      }

      // If bun not selected
      if (!this.selectedBao) {
        window.scrollTo(0, 0);
        this.msgModalBody = 'Please select your sauce.';
        this.errMsgModalShow = true;
        return;
      }

      let items = [];
      let baoItem = this.baoForm[this.selectedBao];

      // Add bun & sauce
      items.push(
        { qty: 1, itemId: this.baoObj.itemId, name: this.baoObj.name },
        { qty: 1, itemId: baoItem.itemId, name: baoItem.name }
      );

      // for (let key in this.beverageForm2) {
      //   let beverageItem = this.beverageForm2[key];
      //   if (beverageItem.count > 0) {
      //     items.push({ qty: 1, itemId: beverageItem.itemId, name: beverageItem.name });
      //     // get only one drink
      //     beverageSelected = true;
      //     break;
      //   }
      // }

      let beverageSelected = false;

      // Add beverage
      for (let key in this.beverageForm2) {
        let beverageItem = this.beverageForm2[key];
        if (beverageItem.selected) {
          items.push({ qty: 1, itemId: beverageItem.itemId, name: beverageItem.name }); // get only one drink
          beverageSelected = true;
          break;
        }
      }

      // If beverage required and not selected
      if (this.beverageRequired && !beverageSelected) {
        this.msgModalBody = 'Please select your beverage.';
        this.errMsgModalShow = true;
        return;
      }

      // Add add-ons (14/8/2021: For Box set only)
      if (this.menuId == this.boxMenuId) {
        for (let key in this.addOnForm) {
          let addOnItem = this.addOnForm[key];
          if (addOnItem.selected) {
            items.push({ qty: 1, itemId: addOnItem.itemId, name: addOnItem.name });
          }
        }
      }

      // Add extra sauce
      for (let key in this.sauceForm) {
        let sauceItem = this.sauceForm[key];
        if (sauceItem.selected) {
          items.push({ qty: 1, itemId: sauceItem.itemId, name: sauceItem.name });
        }
      }

      let finalData = {
        qty: this.qty,
        price: this.setBasePrice,
        label: this.menuItemDetails.label,
        images: this.menuItemDetails.images,
        menuItemId: this.menuItemDetails.itemId,
        items,
      };

      // console.log('finalData', JSON.stringify(finalData, '', 2));
      this.loading = true;
      return this.addToCart(finalData).then(() => {
        this.successModal = true;
        setTimeout(() => {
          this.successModal = false;
          this.loading = false;
          // this.navigateReplace('MenuAll');
          this.resetFields();
          window.scrollTo(0, 0);
        }, 1000);
      });
    },
    // preAddToCart() {
    //   let finalDataArr = [];

    //   let items = [];
    //   let valid = false;

    //   let beverageForm = JSON.parse(JSON.stringify(this.beverageForm2));
    //   let beverageObj = beverageForm[Object.keys(beverageForm)[0]];
    //   let beverageMax = beverageObj.count;
    //   let beverageCurrCount = 0;
    //   for (let key in this.baoForm) {
    //     let item = this.baoForm[key];

    //     if (item.count >= 1) {
    //       // console.log('item', item);
    //       valid = true;
    //       for (let i = 1; i <= item.count; i++) {
    //         finalDataArr.push({
    //           qty: this.qty,
    //           price: this.setBasePrice,
    //           label: this.menuItemDetails.label,
    //           images: this.menuItemDetails.images,
    //           menuItemId: this.menuItemDetails.itemId,
    //           items: [
    //             { qty: 1, itemId: this.baoObj.itemId, name: this.baoObj.name },
    //             { qty: item.count, itemId: item.itemId, name: item.name },
    //           ],
    //         });
    //         if (beverageMax > 0 && beverageMax > beverageCurrCount) {
    //           finalDataArr[finalDataArr.length - 1].items.push({ qty: 1, itemId: beverageObj.itemId, name: beverageObj.name });
    //           beverageCurrCount++;
    //         }
    //         // items.push({ qty: item.count, itemId: item.itemId, name: item.name });
    //       }
    //     }
    //   }

    //   if (!valid) {
    //     this.errMsgModalShow = true;
    //     return;
    //   }

    //   let finalData = {};

    //   // let menuItemId = null;
    //   // console.log('this.baoForm', JSON.stringify(this.baoForm, '', 2));

    //   for (let key in this.sauceForm) {
    //     let sauceItem = this.sauceForm[key];
    //     if (sauceItem.selected) {
    //       finalDataArr[0].items.push({ qty: 1, itemId: sauceItem.itemId, name: sauceItem.name });
    //     }
    //   }

    //   if (this.menuId == this.boxMenuId) {
    //     for (let key in this.addOnForm) {
    //       let addOnItem = this.addOnForm[key];
    //       if (addOnItem.selected) {
    //         finalDataArr[0].items.push({ qty: 1, itemId: addOnItem.itemId, name: addOnItem.name });
    //       }
    //     }
    //   }

    //   // finalData.qty = this.qty;
    //   // finalData.price = this.setBasePrice;
    //   // finalData.label = this.menuItemDetails.label;
    //   // finalData.images = this.menuItemDetails.images;
    //   // finalData.menuItemId = this.menuItemDetails.itemId;
    //   // finalData.items = items;

    //   this.loading = true;
    //   // console.log('finalDataArr', JSON.stringify(finalDataArr, '', 2));
    //   return this.addArrToCart(finalDataArr).then(() => {
    //     this.successModal = true;
    //     setTimeout(() => {
    //       this.successModal = false;
    //       this.loading = false;
    //       // this.navigateReplace('MenuAll');
    //       this.resetFields();
    //       window.scrollTo(0, 0);
    //     }, 1000);
    //   });
    // },
    // selectBao(index, itemId) {
    //   let current = this.baoForm[index];
    //   for (let key in current) {
    //     if (key == itemId) {
    //       this.baoForm[index][key].count = 1;
    //       this.selectedBao = itemId;
    //       this.setPrice = !this.isUndefinedNullOrEmpty(this.baoForm[index][key].promoPrice)
    //         ? this.baoForm[index][key].promoPrice
    //         : this.baoForm[index][key].price;
    //     } else {
    //       this.baoForm[index][key].count = 0;
    //     }
    //   }
    // },
    // selectDrink(itemId) {
    //   for (let key in this.beverageForm) {
    //     if (key == itemId) {
    //       if (this.selectedDrink == itemId) {
    //         // If current selected item ==  last selected item, treat it as unselect and fallback to the base drink
    //         // this.beverageForm[key].count = 0;
    //         // this.beverageForm['301'].count = 1; // 301-Ice Lemon Tea
    //         // this.selectedDrink = 301;
    //       } else {
    //         this.beverageForm[key].count = 1;
    //         this.selectedDrink = itemId;
    //       }
    //     } else {
    //       this.beverageForm[key].count = 0;
    //     }
    //   }
    // },
    getItemImg(imgUrl) {
      if (this.isUndefinedNullOrEmpty(imgUrl)) {
        return this.defaultImg;
      }
      return imgUrl;
    },
    resetFields() {
      // this.baoForm = JSON.parse(JSON.stringify(this.initBaoForm));

      // for (let key in this.beverageForm) {
      //   if (key == this.defaultDrinkId) {
      //     this.beverageForm[key].count = 1;
      //   } else {
      //     this.beverageForm[key].count = 0;
      //   }
      // }

      this.selectedBao = null;

      for (let key in this.beverageForm2) {
        this.beverageForm2[key].count = 0;
      }

      for (let key in this.addOnForm) {
        if (this.addOnForm[key].selected) {
          this.addOnForm[key].selected = false;
        }
      }
      this.addOnTotalAmt = 0;

      for (let key in this.sauceForm) {
        if (this.sauceForm[key].selected) {
          this.sauceForm[key].selected = false;
        }
      }
      this.sauceTotalAmt = 0;

      this.qty = 1;

      // this.selectedDrink = this.defaultDrinkId /* Default drink (Ice Lemon Tea) */;

      this.setTotalPrice = this.initFixedMenuPrice ? this.initFixedMenuPrice : 0;
      this.setBasePrice = this.initFixedMenuPrice ? this.initFixedMenuPrice : 0;
      this.setPrice = this.initFixedMenuPrice ? this.initFixedMenuPrice : 0;
      // this.totalBeveragePrice = 0;
    },
    selectBeverage(itemId) {
      for (let key in this.beverageForm2) {
        const item = this.beverageForm2[key];
        if (item.selected && key != itemId) {
          this.beverageForm2[key].selected = false;
        }
      }
    },
    selectBeverage2(itemId) {
      for (let key in this.beverageForm2) {
        this.beverageForm2[key].selected = false;
      }
      this.beverageForm2[itemId].selected = true;
    },
    // selectBeverage2(itemId) {
    //   const selectedItem = this.beverageForm2[itemId];

    //   if (selectedItem.selected) {
    //     // If item is already selected, unselect it
    //     this.$set(this.beverageForm2, itemId, {
    //       ...selectedItem,
    //       selected: false,
    //     });
    //     this.beverageSelected = false;
    //   } else {
    //     // If item is not selected, check whether there are other items selected, if yes, return and not allowed to select
    //     for (let key in this.beverageForm2) {
    //       const item = this.beverageForm2[key];
    //       if (item.selected) return;
    //     }

    //     // If no items selected, select the chosen item
    //     this.$set(this.beverageForm2, itemId, {
    //       ...selectedItem,
    //       selected: true,
    //     });
    //     this.beverageSelected = true;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';

.top-item-wrap {
  // border: 4px solid #000;
  background-color: #fff5d3;
  border-radius: 4px;
  // box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
  box-shadow: 1px 1px 3px 1px #8e8e8e;
  height: 100%;

  &.selected {
    border: 4px solid #00c569;
    box-shadow: none;
    // box-shadow: 3px 3px 20px 0px #888888
  }
}

.item-wrap {
  // border: 4px solid #000;
  background-color: #fff;
  border-radius: 4px;
  // box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
  box-shadow: 1px 1px 3px 1px #8e8e8e;
  height: 100%;

  &.selected {
    border: 4px solid #00c569;
    box-shadow: none;
    // box-shadow: 3px 3px 20px 0px #888888
  }
}
.special-item-img {
  max-width: 100px;
  width: 100%;
  @include for-sm {
    max-width: 100px;
  }
}
.top-item-img {
  max-width: 150px;
  padding-right: 10px;
  width: 100%;
  @include for-sm {
    max-width: 100px;
  }
}
.item-img {
  max-width: 100px;
  padding: 10px;
  width: 100%;
  @include for-md {
    max-width: 100px;
    padding: 5px;
  }
  @include for-sm {
    max-width: 85px;
    padding: 6px;
  }
}
.top-item-label {
  color: var(--primary-black-color);
  font-size: 1.2rem;
  font-weight: 600;
  @include for-sm {
    font-size: 1rem;
  }
}
.item-label {
  color: var(--grey-dark-1);
  font-size: 0.9rem;
  font-weight: 500;
}
.top-item-price {
  color: var(--red-common);
  font-size: 1.1rem;
  font-weight: 500;
  @include for-sm {
    font-size: 1rem;
  }
}
.item-price {
  color: var(--red-common);
  font-size: 0.9rem;
  font-weight: 500;
}
.font-black {
  color: var(--black);
}
.step-title {
  color: var(--primary-black-color);
  font-size: 1.3rem;
  font-weight: 600;
}
.step-subtitle {
  color: #383838;
  font-size: 1rem;
  font-weight: 500;
}
.set-divider {
  border-color: rgb(0 0 0 / 30%);
}
.plus-minus-field {
  width: 110px;
}
.qty-bar::v-deep {
  max-width: 150px;
  .v-input {
    .v-input__prepend-outer,
    .v-input__append-outer {
      margin: 0;
      .v-btn {
        background-color: #fff;
        border: none;
      }
    }
    .v-input__control {
      margin: 0;
    }
  }
}
.bottom-bar {
  background-color: var(--primary-black-color);
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: 12px;
  height: 80px;

  .total-text {
    color: var(--primary-color);
    font-size: 16px;
  }
  .total-price {
    color: var(--primary-color);
    font-size: 22px;
    font-weight: 500;
  }
}
.modal-body {
  background-color: #231f20;
  height: 100px;

  &__text {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 600;
  }
}
.v-input.v-input--radio-group::v-deep {
  .v-input__slot {
    margin-bottom: 0px;
  }
  .v-messages {
    display: none;
  }
}
.dotted-separator {
  border-top: 1px dashed #bbb;
}
.order-summ {
  box-shadow: 1px 1px 3px 1px #8e8e8e;
}
</style>
